body {
	background-color: black;
	opacity: 0;
	transition: opacity 0.5s ease;
	&.loaded {
		opacity: 1;
	}
}

* {
	background-repeat: no-repeat;
}

html, body {
    width: 100%;
    height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

html {
    width: 1920px;
    height: 1080px;
}

.bs2, .bs2-front, .bs2-shadow {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url('../img/ps2-full-poop.png');

	&.text-pee {
		background-image: url('../img/ps2-full-pee.png');
	}
	background-size: 1920px 1080px;
    background-position: top left;
}
.bs2-front {
	background-image: url('../img/ps2-front-poop.png');

	&.text-pee {
		background-image: url('../img/ps2-front-pee.png');
	}
}
.bs2-shadow {
	background-image: url('../img/tray-shadow.png');
	opacity: 0;
	transition: opacity 0.5s ease;
}
.open .bs2-shadow {
	opacity: 0.5;
}

.bs2-disk {
    width: 664px;
    height: 2000px;
    position: absolute;
    top: -43px;
    left: -417px;
    background-size: contain;
	background-image: url('../img/ps2-disk-tray.png');
	transition: transform 2s ease;
}
.open .bs2-disk {
	transform: translate(600px, 346px);
}

.canvas-disk-container {
	transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(224px, -5px);
    width: 600px;
    height: 600px;
	position: relative;
}
.encompass-disk .canvas-disk-container {
	&:before, &:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 100%;
		background-size: contain;
		background-position: center;
		transform: rotate(-90deg);
	}
	&:before {
		background-image: url(../img/blank-disk-black.png);
	}
	&:after {
		background-image: url(../img/blank-disk.png);
	}
}

.canvas-disk {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 100%;
	overflow: hidden;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.canvas-disk[data-spinning=true] {
	animation: spin 20s infinite linear;
}
